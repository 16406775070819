<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Funcionário"
            name="funcionario"
            placeholder="Funcionário"
            v-model="funcionario"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="praca"
            placeholder="Praça"
            v-model="praca"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data saída"
            name="dataSaida"
            placeholder="Data saída"
            v-model="dataSaidaRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-switch
            :readonly="true"
            v-model="switch1"
            :label="`Status: ${switch1 == true ? 'FINALIZADO' : 'INICIADO'}`"
          ></v-switch>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.quantidade }}</td>
              <!-- <td>{{ props.item.idSacola }}</td> -->
            </template>
          </v-data-table>
        </div>
        <div class="form-btn">
          <v-btn outline @click="imprimir(id)" color="primary">Imprimir</v-btn>
          <v-btn @click="voltar" outline>Voltar</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { ServicoRomaneio } from "../../servicos/servicoRomaneio"
const servicoRomaneio = new ServicoRomaneio()
export default {
  data() {
    return {
      id: null,
      valid: false,
      idFuncionario: null,
      funcionario: "",
      praca: "",
      idPraca: null,
      dataSaida: false,
      dataSaidaRomaneio: null,
      dataRetornoRomaneio: null,
      produtos: [],
      switch1: true,
      headers: [
        { text: "Nome", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Preço", align: "left", sortable: false, value: "precoVenda" },
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Quantidade", value: "quantidade", align: "left", sortable: false }
        // { text: "Sacola", value: "idSacola", align: "left", sortable: false }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoRomaneio.buscarPorId(this.id).then(res => {
        this.view = true
        this.id = res.data.id
        this.idFuncionario = res.data.idFuncionario
        this.funcionario = res.data.funcionario
        this.praca = res.data.praca
        this.idPraca = res.data.idPraca
        this.dataSaidaRomaneio = res.data.dataSaidaString
        this.dataRetornoRomaneio = res.data.dataRetornoString
        this.produtos = res.data.produtos
        this.switch1 = res.data.finalizado
      })
    }
  },
  methods: {
    imprimir(id) {
      servicoRomaneio.imprimir(id)
    },
    voltar() {
      this.$router.push({ name: "romaneio" })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
